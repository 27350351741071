import { useAuth } from "../../context/auth";
import MinyanSection from "./sections/Minyan";
import { useState } from "react";
import { MinyanContext } from "../../context/MinyanContext";
import Get from "../../helpers/ajax/get";
import MinyanForm from "./sections/MinyanForm";
import NoteSection from "./sections/Notes";
import BalanceModal from "../../components/+balance/balance";

const Home = () => {
    const { user, colonyData } = useAuth();
    const [MinyanRecords, setMinyanRecords] = useState(null);

    const fetchMinyanRecords = async () => {
        try {
            var minyanData = await Get(`/api/minyan`);
            setMinyanRecords(minyanData);
        } catch (error) {
            console.log("Error loading minyan records");
        }
    };

    return (
        <MinyanContext.Provider value={{ MinyanRecords, fetchMinyanRecords }}>
            <div>
                <BalanceModal />
                <div className="bg-center bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/welcome_colony.png')" }}>
                    <div className="mx-auto max-w-7xl text-left md:py-36 py-10 px-5">
                        <p className="text-lg md:text-4xl text-yellow-500 md:mb-4 uppercase font-light tracking-widest lg:tracking-super-wide">WELCOME TO MINYAN PORTAL</p>
                        <p className="text-2xl md:text-7xl text-white font-semibold">
                            Hi, {user.first_name} {user.last_name}
                        </p>
                        <p className="mt-3 text-xl text-dependable-gold">{colonyData.name}</p>
                    </div>
                </div>
                <MinyanForm />
                <MinyanSection />
                <NoteSection />
            </div>
        </MinyanContext.Provider>
    );
};

export default Home;
