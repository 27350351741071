import { useAuth } from "../../context/auth";
import Policy from "./sections/policy";

const Policies = () => {
    document.title = "Policies | Dependable";

    const { colonyData } = useAuth();
    const { policies } = colonyData;


    return (
        <div>
            <div className="bg-center bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/main_bg.jpg')" }}>
                <div className="bg-gradient-to-r from-dependable to-dependable/75">
                    <div className="mx-auto max-w-7xl text-left md:py-20 py-10 px-5">
                        <p className="text-lg md:text-6xl text-yellow-500 md:mb-4 uppercase font-light tracking-widest">Policies</p>
                        <p className="text-white mt-2 text-lg">{colonyData.name}</p>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl px-5 mx-auto py-10 ">
                {policies.map((policy) => {
                    return <Policy key={policy._id} policy={policy} />;
                })}
            </div>
        </div>
    );
};

export default Policies;
