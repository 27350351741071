import { useState } from "react";
import { HiPlusSm, HiMinusSm } from "react-icons/hi";

const Policy = ({ policy }) => {
    const [PolicyOpen, setPolicyOpen] = useState(true);

    return (
        <div key={policy._id} className="text-left mb-5 border border-dependable">
            <div className="relative text-white bg-dependable p-5 text-xl font-bold">
                <p className="w-5/6">{policy.title}</p>
                <button onClick={() => setPolicyOpen(!PolicyOpen)} className="rounded-md absolute w-8 h-8 border-2 boreder-white top-5 right-5 flex justify-center items-center">
                    {PolicyOpen && <HiMinusSm className="inline-block" />}
                    {!PolicyOpen && <HiPlusSm className="inline-block" />}
                </button>
            </div>
            <p className={`whitespace-pre-wrap overflow-hidden px-5 transition-all duration-150 ${PolicyOpen ? "max-h-max py-8" : "max-h-0"}`}>{policy.content}</p>
        </div>
    );
};

export default Policy;
