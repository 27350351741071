import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import Get from "../../helpers/ajax/get";
import Post from "../../helpers/ajax/post";

const ResetPassword = () => {
    document.title = "Reset Password | Dependable";
    let { reset_token } = useParams();
    const { fetchUser } = useContext(AuthContext);

    const [password, setPassword] = useState("");
    const [Password2, setPassword2] = useState("");
    const [ResetError, setResetError] = useState(null);
    const [CheckingToken, setCheckingToken] = useState(true);
    const [ValidToken, setValidToken] = useState(true);

    const passCheck = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40})$/;
    const submitDisabaled = !password || !Password2 || Password2 !== password || !passCheck.test(password);

    const validateToken = useCallback(async () => {
        try {
            await Get(`/api/reset/tekenVerify/${reset_token}`);
            setCheckingToken(false);
        } catch (error) {
            setCheckingToken(false);
            setValidToken(false);
        }
    }, [reset_token]);

    const handleReset = async (ev) => {
        ev.preventDefault();
        if (submitDisabaled) {
            return false;
        }
        setResetError(null);
        try {
            await Post("/api/reset/setNewPass", {
                password: password,
                password2: Password2,
                token: reset_token
            });
            await fetchUser();
        } catch (error) {
            setResetError(error.error);
        }
    };

    useEffect(() => {
        validateToken();
    }, [validateToken]);

    return (
        <div style={{ backgroundImage: "url('/images/main_bg.jpg')" }} className="bg-center bg-no-repeat bg-cover">
            <div className="flex justify-center min-h-screen bg-white/90">
                <div className="bg-white w-96 shadow-2xl self-center inline-block rounded-lg p-8">
                    <img alt="logo" src="/images/dependable-logo-reg.jpg" className="inline-block mx-auto w-1/2" />

                    {CheckingToken && (
                        <svg className="w-20 h-20 mx-auto my-10 text-gray-200 animate-spin fill-dependable" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    )}
                    {!ValidToken && (
                        <div className="py-12">
                            <p className="text-red-600">This link has been expired or is invalid</p>
                        </div>
                    )}

                    {ValidToken && !CheckingToken && (
                        <form onSubmit={handleReset}>
                            <h2 className="mt-5 mb-3 text-lg">Reset Password</h2>
                            <input required className="w-full block mb-5 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30 p-3" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <input required className="w-full block mb-3 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30 p-3" placeholder="Retype Password" type="password" value={Password2} onChange={(e) => setPassword2(e.target.value)} />
                            <p className="text-sm text-left mb-3">Password must contain at least 8 characters, have have an uppercase, lowercase and digit</p>
                            <button disabled={submitDisabaled} className="bg-dependable disabled:opacity-50 block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30" type="submit">
                                Reset Password
                            </button>
                        </form>
                    )}
                    <p className="text-right mt-3 text-dependable">
                        <Link to={"/login"}>Login?</Link>
                    </p>
                    {!!ResetError && <p className="text-red-800 text-left mt-4">{ResetError}</p>}
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
