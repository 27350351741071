import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";

const Footer = () => {
    const { authenticated, logout } = useAuth();

    return (
        <footer className="bg-[#0A2F54] text-white">
            <div className="max-w-7xl mx-auto px-5 py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    <div className="col-span-1 text-left">
                        <img src="/images/logo_transparent.png" alt="logo_white" width={350} />
                        <p className="mt-12 text-lg">
                            We offer a full suite of services, managing the bookkeeping, billing, collections, customer service and all aspects of
                            on-site property maintenance and preservation.
                        </p>
                    </div>
                    <div className="col-span-1 md:text-right text-left pt-24">
                        <p>
                            <a
                                rel="noreferrer"
                                href="https://dependable.managebuilding.com/Resident/portal/login"
                                target="_blank"
                                className="border-2 border-dependable-green py-3 px-5 font-semibold inline-block"
                            >
                                Login to Homeowners Portal
                                <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                            </a>
                        </p>
                        <p className="mt-4">
                            {authenticated && (
                                <button onClick={logout} className="border-2 border-dependable-green py-3 px-5 font-semibold inline-block">
                                    Logout
                                    <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                                </button>
                            )}
                            {!authenticated && (
                                <Link to="/login" className="border-2 border-dependable-green py-3 px-5 font-semibold inline-block">
                                    Login to Minyan Tracker &amp; Winterization
                                    <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                                </Link>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className="border-t-2 border-dependable-gold">
                <p className="px-5 max-w-7xl py-10 mx-auto text-xl">
                    &copy; Copyright {new Date().getFullYear()} Dependable Management. All rights reserved. &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/privacy-policy" className="border-b-2 border-white hover:border-dependable-gold">
                        Privacy Policy
                    </Link>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
