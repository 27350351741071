import { useEffect, useState } from "react";
import { FaCalendarAlt, FaTimes } from "react-icons/fa";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import MinyanSignup from "../MinyanSignUp";
import { useAuth } from "../../../../context/auth";
import { useMinyan } from "../../../../context/MinyanContext";
import Hebcal from "hebcal";
import MinyanStatus from "../MinyanStatus";

function LaterSignup({ setLaterSignupOpen }) {
    const [Open, setOpen] = useState(false);
    const { user, colonyData } = useAuth();
    const { MinyanRecords } = useMinyan();
    const [SelectedDate, setSelectedDate] = useState(null);
    var eventName = `שב"ק פר' ${new Hebcal.HDate(new Date(SelectedDate)).getSedra("h")[0]}`;
    var sureCount = MinyanRecords.futureShabbos
        .filter((fs) => moment(fs.date).isSame(SelectedDate, "day"))
        .filter((mr) => !mr.only_if_minyan)[0]?.users
        .reduce((prev, current) => prev + current.poeple_count, 0) || 0;
    var onlyIfCount = MinyanRecords.futureShabbos
        .filter((fs) => moment(fs.date).isSame(SelectedDate, "day"))
        .filter((mr) => mr.only_if_minyan)[0]?.users
        .reduce((prev, current) => prev + current.poeple_count, 0) || 0;
    var zmanInfo = {
        date: new Date(SelectedDate),
        latitude: Number(user.colonyData.lat),
        longitude: Number(user.colonyData.lon),
    };
    var hasMikvah = (colonyData?.hasMikvah === undefined || colonyData?.hasMikvah);
    var shulOpen = colonyData.shulOpenAt.map((s) => new Date(s).getUTCDate() + "-" + new Date(s).getUTCMonth() + "-" + new Date(s).getUTCFullYear()).includes(new Date(SelectedDate).getUTCDate() + "-" + new Date(SelectedDate).getUTCMonth() + "-" + new Date(SelectedDate).getUTCFullYear());
    var mikvahOpen = colonyData.mikvahOpenAt.map((s) => new Date(s).getUTCDate() + "-" + new Date(s).getUTCMonth() + "-" + new Date(s).getUTCFullYear()).includes(new Date(SelectedDate).getUTCDate() + "-" + new Date(SelectedDate).getUTCMonth() + "-" + new Date(SelectedDate).getUTCFullYear());
    const isSignedUp = user.futureMinyanRecords.find((us) => {
        return moment(us.date).isSame(SelectedDate, "day");
    });

    function getNextSaturday() {
        const today = new Date();
        const daysUntilNextSaturday = 6 - today.getDay(); // Get the number of days until the next Saturday
        const nextSaturday = new Date(today.getTime() + daysUntilNextSaturday * 24 * 60 * 60 * 1000); // Calculate the date of the next Saturday
        return nextSaturday;
    }

    const upcomingSaturday = getNextSaturday();

    useEffect(() => {
        setOpen(true);
    }, []);

    const isFutureSaturday = (date) => {
        // is past
        if (date < new Date()) return false;
        if (moment(upcomingSaturday).isSame(date, "day")) return false;

        const dayOfWeek = date.getDay();
        return dayOfWeek === 6;
    };

    const closeLaterSignup = () => {
        setOpen(false);
        setTimeout(() => {
            setLaterSignupOpen(false);
        }, 250);
    };

    const MyContainer = ({ className, children }) => {
        return (
            <div className="border  border-dependable-gold ">
                <CalendarContainer showPopperArrow={true} className={`${className} border-0`}>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    return (
        <div
            className={`fixed top-0 bottom-0 right-0 left-0 bg-white/50 z-[100] flex items-center justify-center transition duration-150 ease-in-out h-[100vh]${Open ? "" : "opacity-10"
                }`}
        >
            <div
                className={`border border-dependable  w-11/12 sm:w-4/6 md:w-3/6 lg:w-2/6 drop-shadow-2xl transition duration-200`}
            >
                <div className=" bg-dependable p-3 md:p-5 flex justify-between items-center">
                    <div className="md:text-xl  text-white mr-10">Signing up for a later Shabbos?</div>
                    <button className="" onClick={closeLaterSignup}>
                        <FaTimes className="text-2xl text-white" />
                    </button>
                </div>
                <div className="bg-white p-5 md:p-8 h-[75vh] overflow-y-scroll">
                    <div className="grid grid-cols-12 items-center gap-1">
                        <div className="col-span-6  xl:col-span-4">
                            <p>Select an upcoming Shabbos </p>
                        </div>

                        <div className="col-span-6  xl:col-span-7 xl:col-end-12">
                            <span className="relative bg-red-100 inline-block">
                                <DatePicker
                                    placeholderText="Select a date"
                                    filterDate={isFutureSaturday}
                                    className="border border-dependable-gold p-2 w-full xl:w-auto h-16"
                                    selected={SelectedDate}
                                    showPopperArrow={false}
                                    onChange={(date) => {
                                        if (!date) return setSelectedDate(null);
                                        setSelectedDate(date);
                                    }}
                                    calendarContainer={MyContainer}
                                />
                                <span className="absolute top-0 bottom-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <FaCalendarAlt className="text-dependable-gold text-lg" />
                                </span>
                            </span>
                        </div>
                        <div className={` col-span-12 mt-6 ${!SelectedDate ? "opacity-20 pointer-events-none" : ""}`}>
                            <MinyanSignup
                                successCallback={closeLaterSignup}
                                eventData={{
                                    date: SelectedDate,
                                    rocordId: isSignedUp ? isSignedUp._id : null,
                                    signedUp: isSignedUp ? true : false,
                                }}
                                futureShabbos={true}
                                date={user.upcomingShabbos.date}
                            />
                            <div className="h-9"></div>
                            {SelectedDate && <MinyanStatus date={SelectedDate} eventName={eventName} isHaliday={false} sureCount={sureCount} onlyIfCount={onlyIfCount} zmanInfo={zmanInfo} hasMikvah={hasMikvah} shulOpen={shulOpen} mikvahOpen={mikvahOpen} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LaterSignup;
