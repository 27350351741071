import { useState } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useAuth } from "../../../context/auth";
import Post from "../../../helpers/ajax/post";

const Info = () => {
    const { user, fetchUser } = useAuth();

    const [FirstName, setFirstName] = useState(user.first_name);
    const [LastName, setLastName] = useState(user.last_name);
    const [Email, setEmail] = useState(user.email);
    const [Phone, setPhone] = useState(user.phone);
    const [Error, setError] = useState(null);
    const [Updating, setUpdating] = useState(false);
    const [UpdatedSuccess, setUpdatedSuccess] = useState(false);

    const updateUser = async () => {
        setUpdating(true);
        setError(null);
        try {
            await Post("/api/user/update", {
                email: Email,
                phone: Phone ? `+1${Phone}` : null,
                first_name: FirstName,
                last_name: LastName,
            });
            fetchUser();
            setUpdatedSuccess(true);
        } catch (error) {
            setError(error.error);
        } finally {
            setUpdating(false);
        }
    };

    return (
        <div className="text-left mb-5 border border-dependable">
            <div className="relative text-white bg-dependable p-5 text-xl font-bold">
                <p>Personal Info</p>
            </div>
            <div className="px-5 py-8">
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">First Name</div>
                    <div className="md:col-span-5 col-span-12">
                        <input disabled={UpdatedSuccess} name="first_name" value={FirstName} onChange={(e) => setFirstName(e.target.value)} type="text" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Last Name</div>
                    <div className="md:col-span-5 col-span-12">
                        <input disabled={UpdatedSuccess} name="last_name" value={LastName} onChange={(e) => setLastName(e.target.value)} type="text" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Email</div>
                    <div className="md:col-span-5 col-span-12">
                        <input disabled={UpdatedSuccess} name="email" value={Email} onChange={(e) => setEmail(e.target.value)} type="email" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Phone #</div>
                    <div className="md:col-span-5 col-span-12">
                        <input disabled={UpdatedSuccess} placeholder="222-222-2222" name="phone" value={Phone} onChange={(e) => setPhone(e.target.value)} type="email" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Username</div>
                    <div className="md:col-span-5 col-span-12">
                        <p className="items-center flex bg-gray-100 cursor-not-allowed select-none focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold">{user.username}</p>
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Unit #</div>
                    <div className="md:col-span-5 col-span-12">
                        <p className="items-center flex bg-gray-100 cursor-not-allowed select-none focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold">{user.unit}</p>
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                    <div className="md:col-span-5 col-span-12">
                        <button disabled={Updating || UpdatedSuccess} onClick={updateUser} className="disabled:opacity-50 border-2 border-dependable-green py-3 px-5 font-semibold inline-block">
                            Update
                            <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                        </button>
                    </div>
                </div>
                {!!Error && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                        <div className="md:col-span-5 col-span-12">
                            <p className="text-red-700">{Error}</p>
                        </div>
                    </div>
                )}
                {UpdatedSuccess && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                        <div className="md:col-span-5 col-span-12">
                            <p className="text-dependable-green">Your account info has been updated</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Info;
