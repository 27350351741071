import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../+sections/Footer";
import Header from "../+sections/Header";

const InfoHome = () => {
    return (
        <div>
            <div className="bg-center bg-no-repeat bg-cover" style={{ backgroundImage: "url('/images/info_main_section.jpg')" }}>
                <div className="min-h-screen flex relative flex-col md:justify-between">
                    <Header />
                    <div>
                        <div className="text-left max-w-7xl mx-auto px-5 pt-60 md:pb-[40vh] 2xl:pb-96 md:pt-36 2xl:pt-72 text-white">
                            <h4 className="uppercase text-3xl md:text-5xl 2xl:text-super tracking-wider font-semibold">Enhancing the value</h4>
                            <h5 className="uppercase text-xl md:text-2xl 2xl:text-4xl text-yellow-500 md:tracking-super-wide 2xl:tracking-super-super-wide mb-1 md: 2xl:mb-5">Of your property investment</h5>
                            <p className="text-lg md:text-xl 2xl:text-3xl tracking-wide font-light md:w-4/6 2xl:w-2/3">We strive to provide complete satisfaction, offering our clients constant assistance and fostering ease of operation.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-center bg-no-repeat bg-cover text-white" style={{ backgroundImage: "url('/images/section_2_bg.png')" }}>
                <div className="min-h-screen flex items-end relative flex-col justify-end bg-gradient-to-b from-black/25 to-dependable">
                    <div className="pb-24 md:mr-48 md:w-1/2 text-left mx-5">
                        <h3 className="md:text-7xl tracking-wide text-4xl">
                            EXPERIENCE SUPERIOR
                            <br />
                            PROPERTY CARE
                        </h3>
                        <div className="md:w-1/3 w-1/2 my-12 border-b-4 border-dependable-gold"></div>
                        <p className="clear-both mb-12 font-light tracking-widest text-xl max-w-4xl">We specialize in managing condominiums, co-ops and rental properties in the upstate New York area, removing the burden of maintaining a property from the homeowners' or property owners' responsibility. We offer a full suite of services, managing the bookkeeping, billing, collections, customer service and all aspects of on-site property maintenance and preservation.</p>
                        <p className="text-right max-w-4xl">
                            <Link to="/contact" className="border-2 border-dependable-green py-3 px-5 font-semibold inline-block float-right">
                                Contact Us Now
                                <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InfoHome;
