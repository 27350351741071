import { React, useState } from 'react';
import Post from "../../helpers/ajax/post";
import { useAuth } from "../../context/auth";
const BalanceModal = ({ blockRequest = false, updateBypassCode = () => { } }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [bypassCode, setBypassCode] = useState("");
    const [Error, setError] = useState(null);
    const [isVerifying, setIsVerifying] = useState(false);
    const { user } = useAuth();
    if (user.balance <= 0 || !isOpen)
        return null;
    async function verifyCode() {
        setIsVerifying(true);
        try {
            await Post("/api/user/verifyBypassCode", {
                user_id: user.user_id,
                bypassCode: bypassCode
            });
            updateBypassCode(bypassCode);
            setIsOpen(false);
        } catch (error) {
            setError(error.error);
        } finally {
            setIsVerifying(false);
        }
    }
    return (<div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-40 overflow-y-auto h-full w-full flex text-center items-center justify-center">
        <div className="bg-white border-4 border-yellow-500 p-8 max-w-md w-full">
            <h2 className="text-2xl md:text-4xl text-yellow-500 mb-4 uppercase ">
                Outstanding Balance
            </h2>
            <p className="text-xl md:text-2xl text-dependable font-semibold mb-6">
                Your current balance is
                <br />
                <text className="text-2xl md:text-4xl">
                    {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    })
                        .format(user.balance)}
                </text>
            </p>
            <div className="flex justify-between">
                {!blockRequest &&
                    <button onClick={() => setIsOpen(false)} className="text-md md:text-lg border-4 border-gray-400 text-gray-700 px-6 py-4 transition duration-300 hover:text-white hover:bg-gray-400/80">
                        Remind Me Later
                    </button>
                }
                <a href="https://dependable.managebuilding.com/Resident/portal/login" target="_blank" rel='noreferrer noopener' className={`text-md md:text-lg border-4 border-dependable-green px-6 py-4 transition duration-300 inline-block hover:bg-dependable-green/80 hover:text-white ${blockRequest ?
                    'mx-auto w-3/4' : ''}`}>
                    Pay Now
                </a>
            </div>
            {blockRequest &&
                <>
                    <br />
                    <p className="text-sm md:text-base text-gray-600 mb-2">
                        Or enter your bypass code
                    </p>
                    <div className="flex justify-center items-center mt-1 space-x-2">
                        <input className="text-xs md:text-sm border border-gray-400 text-gray-700 px-3 py-1 w-1/2" type="text" onChange={(e) => setBypassCode(e.target.value)} placeholder="Enter bypass code" />
                        <button className="text-xs md:text-sm border border-dependable-green px-3 py-1 transition duration-300 inline-block hover:bg-dependable-green/80 hover:text-white" onClick={verifyCode} disabled={isVerifying}>
                            {isVerifying ?
                                'Verifying...' : 'Verify code'}
                        </button>
                    </div>
                    <button onClick={() => window.location.reload()} className="text-dependable text-sm hover:underline text-left block pl-10">
                        <br />
                        Just paid?
                    </button>
                </>
            }
            {!!Error &&
                <p className="text-red-600 mt-4 text-base">
                    {Error}
                </p>
            }
        </div>
    </div >);
};
export default BalanceModal;
