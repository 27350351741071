import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { RiMenu4Fill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Header = () => {
    const { authenticated, logout, colonyData } = useAuth();
    const location = useLocation();
    const [MenuOpen, setMenuOpen] = useState(false);
    const [RequestMenuOpen, setRequestMenuOpen] = useState(false);
    const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);

    useEffect(() => {
        setMenuOpen(false);
        setRequestMenuOpen(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    return (
        <header className="bg-white/90 z-50 relative border-b border-dependable md:bg-white/90 bg-gray-200" onClick={() => setLoginDropdownOpen(false)}>
            <div className="max-w-7xl mx-auto px-5 flex flex-row justify-between">
                <div className="md:py-4 py-2 inline-flex flex-row">
                    <button onClick={() => setMenuOpen(!MenuOpen)} className="md:hidden text-3xl mr-5 border border-dependable px-1.5 rounded">
                        <RiMenu4Fill className="text-dependable" />
                    </button>
                    <Link to={"/"}>
                        <img alt="Logo" src="/images/dependable-logo-condensed.png" className="md:h-12 h-10" />
                    </Link>
                </div>
                <div className={`${MenuOpen ? "max-h-96" : "max-h-0"} md:w-4/6 justify-center md:overflow-visible overflow-hidden duration-500 transition-all md:max-h-max flex-wrap absolute md:relative top-full right-0 left-0 md: z-10 flex md:inline-flex items-center md:bg-transparent bg-dependable md:flex-row flex-col`}>
                    <div className="md:px-4 w-full md:w-auto border-gray-400 border-b md:border-none">
                        <Link to="/about" className="p-2 md:p-0 block md:text-dependable text-white">
                            About
                        </Link>
                    </div>
                    <div className="md:px-4 w-full md:w-auto border-gray-400 border-b md:border-none">
                        <Link to="/contact" className="p-2 md:p-0 block md:text-dependable text-white">
                            Contact
                        </Link>
                    </div>
                    {!!authenticated && (
                        <div className="md:px-4 w-full md:w-auto border-gray-400 border-b md:border-none">
                            <Link to="/policies" className="p-2 md:p-0 block md:text-dependable text-white">
                                Policies
                            </Link>
                        </div>
                    )}
                    {!!authenticated && !colonyData.blockWinterize && (
                        <div className="md:px-4 w-full md:w-auto border-gray-400 border-b md:border-none cursor-pointer relative">
                            <button onClick={() => setRequestMenuOpen(!RequestMenuOpen)} className="w-full relative p-2 md:p-0 block md:text-dependable text-white">
                                Request
                                {!RequestMenuOpen && <FaChevronDown className="inline-block ml-2" />}
                                {RequestMenuOpen && <FaChevronUp className="inline-block ml-2" />}
                            </button>
                            {RequestMenuOpen && (
                                <div className="md:absolute top-full left-0 md:bg-gray-100 md:text-left md:text-dependable text-white text-center">
                                    <p className="md:my-1 md:px-4 ">
                                        <Link to="/winterize" className="py-2 md:py-0 md:border-t-0 block border-gray-400 border-t">
                                            Winterize
                                        </Link>
                                    </p>
                                    <p className="md:my-1 md:px-4 ">
                                        <Link to="/dewinterize" className="py-2 md:py-0 md:border-t-0 block border-gray-400 border-t">
                                            Dewinterize
                                        </Link>
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                    {/* {user?.admin && (
                        <div className="md:px-4 w-full md:w-auto border-gray-400 border-b md:border-none">
                            <Link to={'/admin'} className="p-2 md:p-0 block md:text-dependable text-white">
                                Admin
                            </Link>
                        </div>
                    )} */}

                    {!!authenticated && (
                        <div className="md:px-4 w-full md:w-auto border-gray-400 border-b md:border-none">
                            <Link to="/account" className="p-2 md:p-0 block md:text-dependable text-white">
                                Account
                            </Link>
                        </div>
                    )}
                    {/* <div className="md:px-4 w-full md:w-auto border-gray-400 border-b md:border-none">
                        <a target="_blank" rel="noreferrer" href="https://dependable.managebuilding.com/Resident/portal/login" className="p-2 md:p-0 block md:text-dependable text-white">
                            Homeowners Portal <MdOpenInNew className="inline" />
                        </a>
                    </div> */}
                    {authenticated && (
                        <div className="md:hidden w-full md:w-auto border-gray-400 border-b md:border-none">
                            <button onClick={logout} className="p-2 md:p-0 block md:text-dependable text-white w-full">
                                Logout
                            </button>
                        </div>
                    )}
                    {/* {!authenticated && (
                        <div className="md:hidden w-full md:w-auto border-gray-400 border-b md:border-none">
                            <Link to={"/login"} className="p-2 md:p-0 block md:text-dependable text-white w-full">
                                Login
                            </Link>
                        </div>
                    )} */}
                </div>
                {!authenticated && (
                    <button className="relative inline-flex items-center bg-[#90c255] text-white font-semibold uppercase md:px-7 px-3" onClick={(event) => { event.stopPropagation(); setLoginDropdownOpen(!loginDropdownOpen) }}>
                        <span className="p-2 md:p-0 block text-white w-full">
                            Login
                            {!loginDropdownOpen && <FaChevronDown className="inline-block ml-2" />}
                            {loginDropdownOpen && <FaChevronUp className="inline-block ml-2" />}
                        </span>
                        {loginDropdownOpen && (
                            <div className="absolute right-0 top-full w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <a href="https://dependable.managebuilding.com/Resident/portal/login"
                                    rel="noopener noreferrer"
                                    className="border-2 border-dependable-green py-3 px-5 font-semibold inline-block text-dependable">
                                    Homeowners Portal
                                </a>
                                <Link to="/login" className="border-2 border-dependable-green py-3 px-5 font-semibold inline-block text-dependable">
                                    Minyan Tracker & Winterization
                                </Link>
                            </div>
                        )}
                    </button>
                )}
                {/* {!authenticated && (
                    <Link className="inline-flex items-center bg-[#90c255] text-white uppercase font-semibold md:px-7 px-3" to="/login">
                        <span>Login</span>
                    </Link>
                )} */}
                {authenticated && (
                    <button onClick={logout} className="md:inline-flex items-center bg-[#90c255] text-white uppercase font-semibold md:px-7 px-3">
                        <span>Logout</span>
                    </button>
                )}
            </div>
        </header>
    );
};

export default Header;
