import { useState } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Post from "../../../helpers/ajax/post";

const Password = () => {
    const [Updating, setUpdating] = useState(false);
    const [UpdatedSuccess, setUpdatedSuccess] = useState(false);
    const [Error, setError] = useState(null);
    const [OldPassword, setOldPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [NewPassword2, setNewPassword2] = useState("");

    const updatePassword = async () => {
        setUpdating(true);
        setError(null);
        try {
            await Post("/api/user/updatePassword", {
                oldPassword: OldPassword,
                newPassword: NewPassword,
                newPassword2: NewPassword2,
            });
            setUpdatedSuccess(true);
        } catch (error) {
            setError(error.error);
        } finally {
            setUpdating(false);
        }
    };

    return (
        <div className="text-left mb-5 border border-dependable">
            <div className="relative text-white bg-dependable p-5 text-xl font-bold">
                <p>Update your Password</p>
            </div>
            <div className="px-5 py-8">
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Password</div>
                    <div className="md:col-span-5 col-span-12">
                        <input placeholder="●●●●●●●●●" disabled={UpdatedSuccess} name="password_old" value={OldPassword} onChange={(e) => setOldPassword(e.target.value)} type="password" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">New Password</div>
                    <div className="md:col-span-5 col-span-12">
                        <input placeholder="●●●●●●●●●" disabled={UpdatedSuccess} name="password_new" value={NewPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold">Retype new Password</div>
                    <div className="md:col-span-5 col-span-12">
                        <input placeholder="●●●●●●●●●" disabled={UpdatedSuccess} name="password_new2" value={NewPassword2} onChange={(e) => setNewPassword2(e.target.value)} type="password" className="disabled:opacity-60 focus:ring-2 ring-dependable ring-offset-2 w-full h-12 px-4 border focus:outline-none border-dependable-gold" />
                    </div>
                </div>
                <div className="grid grid-cols-12 md:gap-8 mb-8">
                    <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                    <div className="md:col-span-5 col-span-12">
                        <button disabled={Updating || UpdatedSuccess} onClick={updatePassword} className="disabled:opacity-50 border-2 border-dependable-green py-3 px-5 font-semibold inline-block">
                            Update Password
                            <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                        </button>
                    </div>
                </div>
                {!!Error && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                        <div className="md:col-span-5 col-span-12">
                            <p className="text-red-700">{Error}</p>
                        </div>
                    </div>
                )}
                {UpdatedSuccess && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2 md:col-end-4 col-span-12 self-center font-semibold"></div>
                        <div className="md:col-span-5 col-span-12">
                            <p className="text-dependable-green">Your password has been updated</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Password;
