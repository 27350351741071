import Footer from "../+sections/Footer";
import Header from "../+sections/Header";
import { useAuth } from "../../context/auth";

const About = () => {
    document.title = "About | Dependable";

    const { authenticated } = useAuth();

    return (
        <div>
            {!authenticated && <Header />}
            <div className="bg-center bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/main_bg.jpg')" }}>
                <div className="bg-gradient-to-r from-dependable to-dependable/75">
                    <div className="mx-auto max-w-7xl text-left md:py-20 py-10 px-5">
                        <h1 className="text-lg md:text-6xl text-yellow-500 md:mb-4 uppercase font-light tracking-widest">About Us</h1>
                        <p className="text-white mt-2 text-lg">Dependable Management</p>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl px-5 mx-auto py-16 text-left">
                <h3 className="mb-4 text-3xl font-semibold">Experience Superior Property Care</h3>
                <p className="max-w-4xl text-lg my-8 tracking-wide">
                    Founded in 2006, Dependable Management Services Inc. is renowned for its superior property management services. We specialize in managing condominiums, co-ops and rental properties in the upstate New York area, removing the burden of maintaining a property from the homeowners' or property owners' responsibility. We offer a full suite of services, managing the bookkeeping, billing, collections, customer service and all aspects of on-site property maintenance and preservation.
                    <br />
                    <br />
                    Dependable Management employs a full-time staff of office professionals who provide dedicated customer service. Our skilled on-site team offers property inspections and care to ensure that each property is maintained to perfection.
                    <br />
                    <br />
                    We strive to provide complete satisfaction, offering our clients constant assistance and fostering ease of operation. From basic property upkeep to beautiful landscape upgrades or detailed property improvements, Dependable Management possesses the skill, expertise, and perseverance to provide paramount assistance and outstanding property management services.
                </p>
            </div>
            {!authenticated && <Footer />}
        </div>
    );
};

export default About;
