import { useAuth } from "../../../context/auth";
import { useMinyan } from "../../../context/MinyanContext";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { FaArrowAltCircleRight, FaPen, FaCheck, FaInfoCircle } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import Post from "../../../helpers/ajax/post";
import Delete from "../../../helpers/ajax/delete";
import { Tooltip } from "react-tooltip";
import moment from "moment";

const MinyanSignup = (props) => {
    const { holidayName, holiday, eventData, futureShabbos, successCallback } = props;
    const { user, fetchUser, colonyData } = useAuth();
    const { fetchMinyanRecords, MinyanRecords } = useMinyan();

    const [formFor] = useState(
        `${holiday ? holidayName.replaceAll(" ", "_") : "shabbos"}_${(Math.random() + 1).toString(36).substring(7)}`
    );
    const [PeopleComing, setPeopleComing] = useState(0);
    const [OnlyIfMinyan, setOnlyIfMinyan] = useState(false);
    const [Anonymous, setAnonymous] = useState(false);
    const [reservedMussaf, setReservedMussaf] = useState(false);
    const [IsGuest, setIsGuest] = useState(false);
    const [comingEarlier, setComingEarlier] = useState(false);
    const [wasSignedUp, setWasSignedUp] = useState(false);
    const [isSignedup, setIsSignedup] = useState(false);
    const [Error, setError] = useState(null);
    const [addingToMinyan, setAddingToMinyan] = useState(false);

    var currMinyanRecord = [];

    if (holiday) {
        currMinyanRecord = MinyanRecords.upcomingHolidays.find((uh) => uh.date_title === holidayName)?.users || [];
    } else if (futureShabbos) {
        currMinyanRecord = MinyanRecords.futureShabbos.find((us) => moment(us.date).isSame(eventData.date, "day"))?.users || [];
    } else {
        currMinyanRecord = MinyanRecords.upcomingShabbos;
    }

    const isMussafAvailable = !!!currMinyanRecord.find((unit) => { return unit.reservedMussaf && unit.unit !== user.unit });
    async function prefillForm() {
        var preFilledRecord = {};
        if (holiday) {
            let holidayDisplayName = user.upcomingHolidays.find((hn) => hn.name === holidayName)?.displayName;
            preFilledRecord = user.futureMinyanRecords.find((mr) => { return mr.date_title === holidayDisplayName && mr.user_id === user.user_id }) || {};
        } else if (futureShabbos) {
            preFilledRecord = user.futureMinyanRecords.find((mr) => { return moment(mr.date).isSame(eventData.date, "day") && mr.user_id === user.user_id }) || {};
        } else {
            preFilledRecord = user.futureMinyanRecords.find((mr) => { return moment(mr.date).isSame(user.upcomingShabbos.date, "day") && mr.user_id === user.user_id }) || {};
        }
        setPeopleComing(preFilledRecord?.poeple_count || 0);
        setOnlyIfMinyan(preFilledRecord?.only_if_minyan || false);
        setAnonymous(preFilledRecord?.anonymous || false);
        setReservedMussaf(preFilledRecord?.reservedMussaf || false);
        setIsGuest(preFilledRecord?.is_guest || false);
        setComingEarlier(preFilledRecord?.comingEarlier || false);
        setIsSignedup(Object.keys(preFilledRecord).length > 0);
        setWasSignedUp((preFilledRecord?.poeple_count || 0) > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { prefillForm(); }, [eventData]);

    const addToMinyan = async (e) => {
        e.preventDefault();
        setAddingToMinyan(true);
        setError(null);
        try {
            await Post(`/api/minyan/add`, {
                poeple_count: PeopleComing,
                is_guest: IsGuest,
                only_if_minyan: OnlyIfMinyan,
                holiday: !!holiday,
                added_by: "Self",
                anonymous: Anonymous,
                holidayName: holidayName,
                reservedMussaf: reservedMussaf,
                futureShabbos: !!futureShabbos,
                date: new Date(eventData.date),
                comingEarlier: comingEarlier,
            });
            fetchMinyanRecords();
            await fetchUser();
            await prefillForm();
            if (successCallback) {
                successCallback();
            }
            setAddingToMinyan(false);
        } catch (error) {
            console.log(error);
            setError(error.error || "Error adding to minyan");
            setAddingToMinyan(false);
        }
    };

    const removeFromMinyan = async (e) => {
        e.preventDefault();
        setError(null);
        setAddingToMinyan(true);
        try {
            await Delete(`/api/minyan/${eventData.rocordId}`);
            fetchMinyanRecords();
            await fetchUser();
            await prefillForm();
            setAddingToMinyan(false);
        } catch (error) {
            console.log(error);
            setError(error.error || "Error adding to minyan");
            setAddingToMinyan(false);
        }
    };

    // if (eventData.signedUp) {
    //     return (
    //         <div className="text-lg md:text-2xl">
    //             <p className="my-7">
    //                 You are already signed up for
    //                 <Moment format=" dddd MMM D, YYYY" date={new Date(eventData.date)} />
    //             </p>
    //             <div className="text-right">
    //                 <button type="button" onClick={removeFromMinyan} className="border-4 border-red-600 px-6 py-4">
    //                     Remove
    //                 </button>
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <form onSubmit={(e) => { (wasSignedUp && PeopleComing === 0) ? removeFromMinyan(e) : addToMinyan(e) }} className="text-lg md:text-2xl">
            <div>Sign up by {(!['Friday', 'Saturday'].includes(moment(eventData.date).subtract(2, 'day').format('dddd'))) ?
                moment(eventData.date).subtract(2, 'day').hours(16).minutes(0).format('dddd h:mm A') :
                moment(eventData.date).subtract(3, 'day').hours(16).minutes(0).format('dddd h:mm A')}
                <span className="inline-block ml-3" data-tooltip-id={`${formFor}_cutoff`}>
                    <FaInfoCircle className=" inline-block text-lg" />
                </span>
            </div>
            <br />
            <div className={`${isSignedup ? "opacity-40" : ""} mb-5 grid grid-cols-12 gap-1 items-center`}>
                <span className="col-span-6  xl:col-span-4 text-lg">How many men?</span>
                <div className="border border-dependable-gold  w-36 inline-block h-16 align-middle bg-white relative col-span-6  xl:col-span-7 xl:col-end-12">
                    <input
                        value={PeopleComing}
                        onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                                setPeopleComing(Number(e.target.value));
                            }
                        }}
                        required
                        type="number"
                        min={(wasSignedUp) ? 0 : 1}
                        max={40}
                        disabled={isSignedup}
                        className="bg-transparent w-full h-full p-3 pr-0 focus:outline-none focus:ring-2 "
                    />
                    <button
                        type="button"
                        onClick={() => setPeopleComing(PeopleComing + 1)}
                        disabled={isSignedup}
                        className="text-white z-2 absolute right-0 top-0 w-7 p-0 items-center justify-center flex bg-dependable-gold h-1/2 overflow-hidden"
                    >
                        <RiArrowUpSFill className="inline" />
                    </button>
                    <button
                        type="button"
                        onClick={() => !!PeopleComing && setPeopleComing(PeopleComing - 1)}
                        disabled={isSignedup}
                        className="text-white z-2 absolute right-0 bottom-0 w-7 p-0 items-center justify-center flex bg-dependable-gold h-1/2 overflow-hidden"
                    >
                        <RiArrowDownSFill className="inline" />
                    </button>
                </div>
            </div>
            <div className={`${isSignedup ? "opacity-40" : ""} mb-5 select-none`}>
                <label className="text-sm flex items-center md:text-lg" htmlFor={`${formFor}_guest`}>
                    <input
                        id={`${formFor}_guest`}
                        className="checked:bg-dependable-gold w-0 h-0"
                        type="checkbox"
                        onChange={(e) => setIsGuest(e.target.checked)}
                        checked={IsGuest}
                        disabled={isSignedup}
                    />
                    <span
                        className={` w-8 h-8 inline-flex items-center justify-center align-text-bottom mr-2 md:mr-5 border border-dependable-gold ${IsGuest ? "bg-dependable-gold" : "bg-white"
                            }`}
                    >
                        {!!IsGuest && <FaCheck className="text-white" />}
                    </span>
                    I'm a guest of {user.first_name} {user.last_name}
                </label>
            </div>
            <div className={`${isSignedup ? "opacity-40" : ""} mb-5 select-none`}>
                <label className="text-sm flex items-center md:text-lg" htmlFor={`${formFor}_OnlyIfMinyan`}>
                    <input
                        id={`${formFor}_OnlyIfMinyan`}
                        className="checked:bg-dependable-gold w-0 h-0"
                        type="checkbox"
                        onChange={(e) => setOnlyIfMinyan(e.target.checked)}
                        disabled={isSignedup}
                        checked={OnlyIfMinyan}
                    />
                    <span
                        className={` w-8 h-8 inline-flex items-center justify-center align-text-bottom mr-2 md:mr-5 border border-dependable-gold ${OnlyIfMinyan ? "bg-dependable-gold" : "bg-white"
                            }`}
                    >
                        {!!OnlyIfMinyan && <FaCheck className="text-white" />}
                    </span>
                    I'm only coming if there is minyan
                </label>
            </div>
            <div className={`${isSignedup ? "opacity-40" : ""} mb-5 select-none flex`}>
                <label className="text-sm flex items-center md:text-lg" htmlFor={`${formFor}_Anonymous`}>
                    <input
                        id={`${formFor}_Anonymous`}
                        className="checked:bg-dependable-gold w-0 h-0"
                        type="checkbox"
                        onChange={(e) => setAnonymous(e.target.checked)}
                        disabled={isSignedup}
                        checked={Anonymous}
                    />
                    <span
                        className={` w-8 h-8 inline-flex items-center justify-center align-text-bottom mr-2 md:mr-5 border border-dependable-gold ${Anonymous ? "bg-dependable-gold" : "bg-white"
                            }`}
                    >
                        {!!Anonymous && <FaCheck className="text-white" />}
                    </span>
                    I'd wish to add as anonymous
                </label>
                <span className="inline-block ml-3" data-tooltip-id={`${formFor}_tooltip_anonymous`}>
                    <FaInfoCircle className=" inline-block text-lg" />
                </span>
            </div>
            <div className={`mb-5 select-none ${((!isMussafAvailable && !reservedMussaf) || isSignedup) ? "opacity-40" : ""} flex`}>
                <label className="text-sm flex items-center md:text-lg" htmlFor={`${formFor}_reservedMussaf`}>
                    <input
                        id={`${formFor}_reservedMussaf`}
                        className="checked:bg-dependable-gold w-0 h-0"
                        type="checkbox"
                        onChange={(e) => setReservedMussaf(e.target.checked)}
                        checked={reservedMussaf}
                        disabled={(!isMussafAvailable && !reservedMussaf) || isSignedup}
                    />
                    <span
                        className={` w-8 h-8 inline-flex items-center justify-center align-text-bottom mr-2 md:mr-5 border border-dependable-gold ${reservedMussaf ? "bg-dependable-gold" : "bg-white"
                            }`}
                    >
                        {!!reservedMussaf && <FaCheck className="text-white" />}
                    </span>
                    <span>
                        I'd like to reserve <i>Maftir & Mussaf</i>
                    </span>
                </label>
                <span className="inline-block ml-3" data-tooltip-id={`${formFor}_tooltip_missuf`}>
                    <FaInfoCircle className=" inline-block text-lg" />
                </span>
            </div>
            {(colonyData?.openOnThu === true && !['Friday', 'Saturday'].includes(moment(eventData.date).subtract(2, 'day').format('dddd'))) && <div className={`mb-5 select-none ${(isSignedup) ? "opacity-40" : ""} flex`}>
                <label className="text-sm flex items-center md:text-lg" htmlFor={`${formFor}_comingEarlier`}>
                    <input
                        id={`${formFor}_comingEarlier`}
                        className="checked:bg-dependable-gold w-0 h-0"
                        type="checkbox"
                        onChange={(e) => setComingEarlier(e.target.checked)}
                        checked={comingEarlier}
                        disabled={isSignedup}
                    />
                    <span
                        className={` w-8 h-8 inline-flex items-center justify-center align-text-bottom mr-2 md:mr-5 border border-dependable-gold ${comingEarlier ? "bg-dependable-gold" : "bg-white"
                            }`}
                    >
                        {!!comingEarlier && <FaCheck className="text-white" />}
                    </span>
                    <span>
                        Coming on {moment(eventData.date).subtract(2, 'day').format('dddd')}
                    </span>
                </label>
            </div>}
            <div className="text-right">
                {!isSignedup && <button type="submit" className="text-lg md:text-2xl border-4 border-dependable-green px-6 py-4">Update
                    {addingToMinyan ? <div className="ml-3 spinner-border animate-spin inline-block w-8 h-8 border-4 text-dependable-green rounded-full"></div> : <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />}
                </button>}
                {isSignedup && <button className="text-lg md:text-2xl border-4 border-dependable-green px-6 py-4" onClick={() => setIsSignedup(false)}>
                    Edit
                    <FaPen className="inline-block text-dependable-green ml-3 align-text-top" />
                </button>
                }
            </div>

            {!!Error && <p className="text-red-600 mt-4 text-base">{Error}</p>}
            <Tooltip id={`${formFor}_tooltip_missuf`} className="z-50">
                <p className="text-center">
                    <b>Maftir & Mussaf</b> can only be reserved
                    <br />
                    for 1 person per week.
                    {(!isMussafAvailable && !reservedMussaf) && (
                        <span>
                            <br />
                            <br />
                            <b>Maftir & Mussaf</b> is already <br />
                            reserved for this week.
                        </span>
                    )}
                </p>
            </Tooltip>
            <Tooltip id={`${formFor}_tooltip_anonymous`} className="z-50">
                <p className="text-center">
                    Other members will not be able to see your name
                    <br />
                    unless they are singed up for 12 hours.
                </p>
            </Tooltip>
            <Tooltip id={`${formFor}_cutoff`} className="z-50">
                <p className="text-center">
                    We kindly ask that you sign up at least 2 days
                    <br />in advance so that we can confirm the
                    <br />minyan and open the facilities accordingly.
                </p>
            </Tooltip>
        </form >
    );
};

export default MinyanSignup;
