import { FaCheck } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { useAuth } from "../../../context/auth";
import { useMinyan } from "../../../context/MinyanContext";
import Moment from "react-moment";
import moment from "moment";

const UnitList = (props) => {
    const { date, title, holiday, futureShabbos } = props;
    const { MinyanRecords } = useMinyan();
    const { colonyData } = useAuth();
    var openUnits = [];
    var totalPeople = 0;
    if (MinyanRecords) {
        if (holiday) {
            openUnits = MinyanRecords.upcomingHolidays.find((uh) => uh.date_title === holiday)?.users || [];
        } else if (futureShabbos) {
            openUnits =
                MinyanRecords.futureShabbos.find((us) => moment(us.date).isSame(moment(futureShabbos.date), "day"))?.users || [];
        } else {
            openUnits = MinyanRecords.upcomingShabbos;
        }
    }
    openUnits.forEach((unit) => (totalPeople += unit.poeple_count));
    var hasMinyan = totalPeople >= 10;

    return (
        <div className="my-12">
            <p className="text-white mb-2 text-2xl tracking-wider">
                <Moment format="dddd MMM Do, YYYY" date={new Date(date)} />
            </p>
            {!!title && <p className="text-dependable-green mb-2 text-2xl tracking-wider">{title}</p>}
            <div className="min-h-[12rem] bg-white/80 border border-dependable-gold">
                <table className="w-full text-center border-collapse table-fixed">
                    <thead className="bg-dependable-gold">
                        <tr>
                            <td className="py-2 text-white border-transparent">Name</td>
                            <td className="py-2 text-white border-transparent">Unit #</td>
                            <td className="py-2 text-white border-transparent">Coming</td>
                            {(colonyData?.openOnThu === true && !['Friday', 'Saturday'].includes(moment(date).subtract(2, 'day').format('dddd'))) &&
                                <td className="py-2 text-white border-transparent">{'Coming on ' + moment(date).subtract(2, 'day').format('dddd')}</td>}
                            <td className="py-2 text-white border-transparent">Mussaf / Maftir</td>
                            <td className="py-2 text-white border-transparent">Sign-up Time</td>
                        </tr>
                    </thead>
                    {!!openUnits.length && (
                        <tbody>
                            {openUnits.map((unit, index) => {
                                return (
                                    <tr key={index} className="border-b">
                                        <td className="py-2">
                                            {unit.user_name} {unit.is_guest ? "(guest)" : ""}
                                        </td>
                                        <td className="py-2">{unit.unit}</td>
                                        <td className="py-2">
                                            {unit.poeple_count} {unit.poeple_count > 1 ? "People" : "Person"}{" "}
                                            {unit.only_if_minyan && !hasMinyan ? "(Only if minyan)" : ""}
                                        </td>
                                        {(colonyData?.openOnThu === true && !['Friday', 'Saturday'].includes(moment(date).subtract(2, 'day').format('dddd'))
                                            && unit.comingEarlier) &&
                                            <td className="py-2 text-center"><FaCheck className=" text-dependable-gold   inline" /></td>}
                                        {(colonyData?.openOnThu === true && !['Friday', 'Saturday'].includes(moment(date).subtract(2, 'day').format('dddd'))
                                            && !unit.comingEarlier) &&
                                            <td className="py-2 text-center"><GrClose className="opacity-30 inline" /></td>}
                                        <td className="py-2 text-center">
                                            {unit.reservedMussaf && <FaCheck className=" text-dependable-gold   inline" />}
                                            {!unit.reservedMussaf && <GrClose className="opacity-30 inline" />}
                                        </td>
                                        <td className="py-2">
                                            <Moment format="M/D/YYYY, ddd h:mma" date={new Date(unit.timeAdded)} />
                                            {moment(unit.timeAdded).isAfter(((!['Friday', 'Saturday'].includes(moment(date).subtract(2, 'day').format('dddd'))) ?
                                                moment(date).subtract(2, 'day')
                                                : moment(date).subtract(3, 'day')).hours(16).minutes(0)) && <div className="text-red-600">(After cut off  time)</div>}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
                {!MinyanRecords && (
                    <div className="flex justify-center items-center pt-6">
                        <div className="spinner-border animate-spin inline-block w-20 h-20 border-4 text-dependable rounded-full"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UnitList;
