import Footer from "../+sections/Footer";
import Header from "../+sections/Header";
import { useAuth } from "../../context/auth";

const PrivacyPolicy = () => {
    document.title = "Privacy Policy | Dependable";

    const { authenticated } = useAuth();

    return (
        <div>
            {!authenticated && <Header />}
            <div className="bg-center bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/main_bg.jpg')" }}>
                <div className="bg-gradient-to-r from-dependable to-dependable/75">
                    <div className="mx-auto max-w-7xl text-left md:py-20 py-10 px-5">
                        <h1 className="text-lg md:text-6xl text-yellow-500 md:mb-4 uppercase font-light tracking-widest">Privacy Policy</h1>
                        <p className="text-white mt-2 text-lg">Dependable Management</p>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl px-5 mx-auto py-16 text-left">
                <h3 className="mb-4 text-xl font-semibold">Last Updated: 11/1/2023</h3>
                <p className="max-w-4xl text-lg my-8 tracking-wide">
                    Dependable Management we operates the website dependablms.com (the "Site"). This Privacy Policy outlines our practices regarding
                    the collection, use, and disclosure of personal information when users visit and interact with our Site.
                    <br />
                    <br />
                    1. Information We Collect:
                    <br />
                    User-Provided Information: We may collect personal information provided voluntarily by users, including but not limited to names,
                    contact information, and other details submitted through signup forms.
                    <br />
                    Automatically Collected Information: We may gather certain information automatically, such as IP addresses, browser type, and
                    device information, to enhance the user experience and for analytics purposes.
                    <br />
                    <br />
                    2. Use of Information:
                    <br />
                    We use the collected information for the following purposes:
                    <br />
                    To facilitate user access to the Site and its features.
                    <br />
                    To manage homeowner sign-ups and property visit schedules.
                    <br />
                    To send alerts, notifications, and updates to homeowners who have opted to receive them.
                    <br />
                    For analytics and improvement of our services.
                    <br />
                    <br />
                    3. Sharing of Information:
                    <br />
                    We do not sell, trade, or otherwise transfer personal information to outside parties. However, we may share information with
                    trusted third parties who assist us in operating the Site or conducting our business, provided that they agree to keep the
                    information confidential.
                    <br />
                    <br />
                    4. Data Security: We implement reasonable security measures to protect against unauthorized access, alteration, disclosure, or
                    destruction of personal information.
                    <br />
                    <br />
                    5. Third-Party Links:
                    <br />
                    Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these
                    third-party sites.
                    <br />
                    <br />
                    6. Your Choices:
                    <br />
                    Users can manage their communication preferences and opt-out of certain communications by following the instructions in the
                    communication.
                    <br />
                    <br />
                    7. Changes to This Privacy Policy:
                    <br />
                    We reserve the right to update or change this Privacy Policy at any time. The date of the latest revision will be indicated at the
                    top of this page.
                    <br />
                    <br />
                    8. Contact Us:
                    <br />
                    If you have any questions or concerns about this Privacy Policy, please contact us at service@dependablems.com.
                </p>
            </div>
            {!authenticated && <Footer />}
        </div>
    );
};

export default PrivacyPolicy;
