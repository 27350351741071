import { FaEnvelope, FaMapMarker, FaPhoneAlt, FaPrint } from "react-icons/fa";
import Footer from "../+sections/Footer";
import Header from "../+sections/Header";
import { useAuth } from "../../context/auth";

const Contact = () => {
    document.title = "Contact Us | Dependable";

    const { authenticated, colonyData } = useAuth();

    return (
        <div>
            {!authenticated && <Header />}
            <div className="bg-center bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/main_bg.jpg')" }}>
                <div className="bg-gradient-to-r from-dependable to-dependable/75">
                    <div className="mx-auto max-w-7xl text-left md:py-20 py-10 px-5">
                        <h1 className="text-lg md:text-6xl text-yellow-500 md:mb-4 uppercase font-light tracking-widest">Contact Us</h1>
                        <p className="text-white mt-2 text-lg">Dependable Management</p>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl px-5 mx-auto py-16 text-left">
                <h3 className="mb-4 text-3xl font-semibold">Handle with Care</h3>
                <p className="max-w-4xl text-lg my-3 tracking-wide">Dependable Management's services will ease the burden of property management and increase efficiency of operation. Contact us today for more information on how we can give your property the ultimate in care and maintenance, protecting your investment and enhancing your success.</p>

                <div className="max-w-xl my-12 ">
                    <div className="relative">
                        <p className="border-b-2 border-dependable-dark p-3 text-xl font-semibold tracking-wide text-dependable-dark">Contact Info</p>
                        <div className="w-1/3 absolute border-b-2 border-dependable-gold bottom-0 left-0"></div>
                    </div>
                    <div className="p-4">
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">
                                <FaMapMarker className="inline-block mr-2 align-text-top" />
                                Address:
                            </span>
                            <span>Po box 416, Monroe NY 10949.</span>
                        </p>
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">
                                <FaEnvelope className="inline-block mr-2 align-text-top" />
                                Email:
                            </span>
                            <span>service@dependablems.com</span>
                        </p>
                        {authenticated && (
                            <p className="mb-1.5">
                                <span className="font-semibold inline-block mr-2">
                                    <FaEnvelope className="inline-block mr-2 align-text-top" />
                                    Colony Email:
                                </span>
                                <span>{colonyData.email}</span>
                            </p>
                        )}
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">
                                <FaPhoneAlt className="inline-block mr-2 align-text-top" />
                                Phone:
                            </span>
                            <span>+1845 783 1063</span>
                        </p>
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">
                                <FaPrint className="inline-block mr-2 align-text-top" />
                                Fax:
                            </span>
                            <span>+1888 205 1403</span>
                        </p>
                    </div>
                </div>

                <div className="max-w-xl my-12 ">
                    <div className="relative">
                        <p className="border-b-2 border-dependable-dark p-3 text-xl font-semibold tracking-wide text-dependable-dark">Office Hours</p>
                        <div className="w-1/3 absolute border-b-2 border-dependable-gold bottom-0 left-0"></div>
                    </div>
                    <div className="p-4">
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">Monday - Thursday:</span>
                            <span>9:30am to 4:30pm</span>
                        </p>
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">Friday:</span>
                            <span>9:30am to 12:00pm</span>
                        </p>
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">Saturday:</span>
                            <span>Closed</span>
                        </p>
                        <p className="mb-1.5">
                            <span className="font-semibold inline-block mr-2">Saturday:</span>
                            <span>Closed</span>
                        </p>
                    </div>
                </div>
            </div>
            {!authenticated && <Footer />}
        </div>
    );
};

export default Contact;
