import { useAuth } from "../../../context/auth";

const NoteSection = () => {
    const { colonyData } = useAuth();

    if(!colonyData.notes.length) return null;

    return (
        <div className="max-w-7xl mx-auto px-5 py-14 text-left">
            <p className="text-dependable text-4xl md:text-7xl uppercase font-semibold">Notes</p>
            {colonyData.notes.map(note => (
                <div key={note._id} className="bg-gray-100 p-3 md:p-8 my-4 md:my-8 text-lg md:text-xl">
                    <p>{note.content}</p>
                </div>
            ))}
        </div>
    );
};

export default NoteSection;
