import { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import { useMinyan } from "../../../context/MinyanContext";
import UnitList from "./UnitList";

const MinyanSection = () => {
    const { user } = useAuth();
    const { fetchMinyanRecords, MinyanRecords } = useMinyan();
    const [ShowFutureShabbos, setShowFutureShabbos] = useState(false);

    useEffect(() => {
        fetchMinyanRecords();
        // eslint-disable-next-line
    }, []);

    if (!MinyanRecords) return null;

    return (
        <div style={{ backgroundImage: "url(/images/main_bg.jpg)" }} className="bg-center bg-no-repeat bg-cover">
            <div className="bg-gradient-to-b md:bg-gradient-to-r from-dependable-dark/90 via-dependable-dark/75 to-dependable-dark/90">
                <div className="max-w-7xl px-5 py-28 text-left mx-auto">
                    <h5 className="uppercase text-white text-5xl">Units open</h5>
                    <UnitList date={user.upcomingShabbos.date} />
                    {user.upcomingHolidays.map((upcomingHoliday) => (
                        <UnitList
                            key={upcomingHoliday.name}
                            holiday={upcomingHoliday.displayName}
                            title={`${upcomingHoliday.displayName} ${upcomingHoliday.emoji || ""}`}
                            date={upcomingHoliday.date}
                        />
                    ))}

                    {!!MinyanRecords.futureShabbos.length && (
                        <div className="mt-20">
                            <button
                                onClick={() => setShowFutureShabbos(!ShowFutureShabbos)}
                                className="py-3 px-6 rounded-lg text-white bg-dependable border border-dependable-green  text-base"
                            >
                                {ShowFutureShabbos ? "Hide" : "Show"} future Shabbosim signups
                            </button>
                            {ShowFutureShabbos && (
                                <div className="-mt-8">
                                    {MinyanRecords.futureShabbos.map((futureShabbos) => (
                                        <UnitList
                                            futureShabbos={futureShabbos}
                                            key={futureShabbos.date}
                                            date={futureShabbos.date}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MinyanSection;
