import React, { useState } from "react";
import { Link } from "react-router-dom";
import Post from "../../helpers/ajax/post";

const ForgotPassword = () => {
    document.title = "Forgot Password | Dependable";

    const [ResetError, setResetError] = useState(null);
    // const [Username, setUsername] = useState("");
    const [Email, setEmail] = useState("");
    const [ResetRequestSuccess, setResetRequestSuccess] = useState(null);

    const submitDisabaled = !Email /* || !Username */ || ResetRequestSuccess;

    const handleForgot = async (ev) => {
        ev.preventDefault();
        if (submitDisabaled) {
            return false;
        }
        setResetError(null);
        try {
            await Post("/api/reset/forgot", {
                // username: Username,
                email: Email,
            });
            setResetRequestSuccess(true)
        } catch (error) {
            setResetError(error.error);
        }
    };

    return (
        <div style={{ backgroundImage: "url('/images/main_bg.jpg')" }} className="bg-center bg-no-repeat bg-cover">
            <div className="flex justify-center min-h-screen bg-white/90">
                <div className="bg-white w-96 shadow-2xl self-center inline-block rounded-lg p-8">
                    <img alt="logo" src="/images/dependable-logo-reg.jpg" className="inline-block mx-auto w-1/2" />

                    <form onSubmit={handleForgot}>
                        <h2 className="mt-5 text-lg">Don't have Password?</h2>
                        <p className="mb-5 text-xs">Please fill in your email address below to set your password</p>
                        {/* <input disabled={ResetRequestSuccess} required className="w-full block mb-5 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30 p-3" placeholder="Username" type="text" value={Username} onChange={(e) => setUsername(e.target.value)} /> */}
                        <input disabled={ResetRequestSuccess} required className="w-full block mb-5 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30 p-3" placeholder="Email" type="email" value={Email} onChange={(e) => setEmail(e.target.value)} />
                        <button disabled={submitDisabaled} className="bg-dependable disabled:opacity-50 block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30" type="submit">
                            Set Password
                        </button>
                        {ResetRequestSuccess && (
                            <p className="text-left mt-3 text-sm">
                                Please check your email for further instructions
                                <br />make sure to check your spam folder
                            </p>
                        )}
                    </form>
                    <p className="text-right mt-3 text-dependable">
                        <Link to={"/login"}>Login?</Link>
                    </p>
                    {!!ResetError && <p className="text-red-800 text-left mt-4">{ResetError}</p>}
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
