const Get = (url) =>
    new Promise((resolve, reject) => {
        return fetch(url)
            .then((response) => {
                var data = response.json()
                if(!response.ok) throw data;
                return data
            })
            .then((data) => resolve(data))
            .catch(reject);
    });

export default Get;
