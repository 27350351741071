import { useState } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useAuth } from "../../../context/auth";
import Post from "../../../helpers/ajax/post";

const NotificationSettings = () => {
    const { user, fetchUser } = useAuth();

    const [Error, setError] = useState(null);
    const [UpdateSuccess, setUpdateSuccess] = useState(false);
    const [Notifications, setNotifications] = useState(user.notifications);
    const [NotificationMethod, setNotificationMethod] = useState(user.notificationMethod);

    const updateNotifications = async (notificationMethod, notifications) => {
        try {
            setError(null);
            await Post("/api/user/updateNotification", {
                notificationMethod,
                notifications,
            });

            setUpdateSuccess(true);
            setTimeout(() => {
                setUpdateSuccess(false);
            }, 1750);
            fetchUser();
        } catch (error) {
            setError(error.error);
        }
    };

    return (
        <div className="text-left mb-5 border border-dependable">
            <div className="relative text-white bg-dependable p-5 text-xl font-bold">
                <p>Notifications settings</p>
            </div>
            <div className="px-5 py-8">
                {user.notifications === "N" && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2  md:col-end-4 col-span-12 self-center font-semibold"></div>
                        <div className="md:col-span-5 col-span-12">
                            <p className="mb-4 text-xl">Notifications disabled</p>
                            <button
                                onClick={() => {
                                    updateNotifications("E", "A");
                                }}
                                className="disabled:opacity-50 border-2 border-dependable-green py-3 px-5 font-semibold inline-block"
                            >
                                Enable Notifications
                                <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                            </button>
                        </div>
                    </div>
                )}

                {user.notifications !== "N" && (
                    <>
                        <div className="grid grid-cols-12 md:gap-8 mb-8">
                            <div className="md:col-span-2  md:col-end-4 col-span-12 self-center font-semibold">Notification method:</div>
                            <div className="md:col-span-5 col-span-12">
                                <div className="inline-block rounded-md overflow-hidden border border-dependable-gold">
                                    <button type="button" onClick={() => setNotificationMethod("T")} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${NotificationMethod === "T" ? "bg-dependable-gold text-white" : ""}`}>
                                        Text
                                    </button>
                                    <button type="button" onClick={() => setNotificationMethod("E")} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${NotificationMethod === "E" ? "bg-dependable-gold text-white" : ""}`}>
                                        Email
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 md:gap-8 mb-8">
                            <div className="md:col-span-2  md:col-end-4 col-span-12 self-center font-semibold">Send Notification:</div>
                            <div className="md:col-span-5 col-span-12">
                                <div className="inline-block rounded-md overflow-hidden border border-dependable-gold">
                                    <button type="button" onClick={() => setNotifications("A")} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${Notifications === "A" ? "bg-dependable-gold text-white" : ""}`}>
                                        Every time
                                    </button>
                                    <button type="button" onClick={() => setNotifications("M")} className={`focus:outline-none transition duration-200 py-2 px-10 font-semibold ${Notifications === "M" ? "bg-dependable-gold text-white" : ""}`}>
                                        Once there is minyan
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 md:gap-8 mb-8">
                            <div className="md:col-span-2  md:col-end-4 col-span-12 self-center font-semibold"></div>
                            <div className="md:col-span-5 col-span-12">
                                <button
                                    onClick={() => {
                                        updateNotifications(NotificationMethod, Notifications);
                                    }}
                                    className="disabled:opacity-50 border-2 border-dependable-green py-3 px-5 font-semibold inline-block"
                                >
                                    Update Notifications Settings
                                    <FaArrowAltCircleRight className="inline-block text-dependable-green ml-3 align-text-top" />
                                </button>
                                <br />
                                <button
                                    onClick={() => {
                                        updateNotifications("E", "N");
                                    }}
                                    className="hover:underline inline-block mt-9"
                                >
                                    Disable Notifications
                                </button>
                            </div>
                        </div>
                    </>
                )}
                {(UpdateSuccess || Error) && (
                    <div className="grid grid-cols-12 md:gap-8 mb-8">
                        <div className="md:col-span-2  md:col-end-4 col-span-12 self-center font-semibold"></div>
                        {UpdateSuccess && <div className="md:col-span-5 col-span-12 text-dependable-green">Your notifications settings have been updated</div>}
                        {Error && <div className="md:col-span-5 col-span-12 text-red-600">{Error}</div>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationSettings;
