import "./App.css";
import { AuthContext } from "./context/auth";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Home from "./components/+home";
import Login from "./components/+login/Login";
import PrivateRoute from "./PrivateRoute";
import { useEffect, useState } from "react";
import Get from "./helpers/ajax/get";
import InfoHome from "./components/+info/InfoHome";
import ResetPassword from "./components/+login/ResetPassword";
import ForgotPassword from "./components/+login/ForgotPassword";
import Join from "./components/+login/Join";
import Header from "./components/+sections/Header";
import Policies from "./components/+policies";
import Footer from "./components/+sections/Footer";
import Account from "./components/+account";
import About from "./components/+about";
import Request from "./components/+request";
import Contact from "./components/+contact";
import "react-tooltip/dist/react-tooltip.css";
import PrivacyPolicy from "./components/+privacy";

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [colonyData, setColonyData] = useState(null);
    const navigate = useNavigate();

    document.title = "Home | Dependable";

    const fetchUser = async () => {
        try {
            var userData = await Get(`/api/user?_=${Date.now()}`);
            var colonyDataGet = await Get(`/api/colony?_=${Date.now()}`);
            setUser(userData);
            setAuthenticated(true);
            setColonyData(colonyDataGet);
        } catch (error) {
            console.log("not logged in");
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        Get("/logout")
            .then(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setUser(null);
                setAuthenticated(false);
                navigate("/");
            })
            .catch(console.log);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <div className="text-center">
            {loading && (
                <div className="h-screen flex items-center justify-center">
                    <div className="ml-4 spinner-border animate-spin inline-block w-24 h-24 border-4 text-dependable rounded-full"></div>
                </div>
            )}

            {!loading && (
                <AuthContext.Provider value={{ authenticated, setAuthenticated, user, fetchUser, logout, colonyData }}>
                    {authenticated && <Header />}
                    <Routes>
                        <Route exact path="/" element={authenticated ? <Home /> : <InfoHome />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route exact path="/contact" element={<Contact />} />
                        <Route exact path="/login" element={authenticated ? <Navigate to={`/`} /> : <Login />} />
                        <Route
                            exact
                            path="/reset/:reset_token"
                            element={authenticated ? <Navigate to={`/`} /> : <ResetPassword />}
                        />
                        <Route exact path="/join/:join_token" element={authenticated ? <Navigate to={`/`} /> : <Join />} />
                        <Route exact path="/forgot" element={authenticated ? <Navigate to={`/`} /> : <ForgotPassword />} />

                        <Route path="/" element={<PrivateRoute />}>
                            <Route exact path="/policies" element={<Policies />} />
                            <Route exact path="/account" element={<Account />} />
                            <Route exact path="/winterize" element={<Request />} />
                            <Route exact path="/dewinterize" element={<Request />} />
                            <Route path="*" element={<Navigate to={`/`} />} />
                        </Route>
                    </Routes>
                    {authenticated && <Footer />}
                </AuthContext.Provider>
            )}
        </div>
    );
}

export default App;
