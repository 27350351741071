import { createContext, useContext } from "react";

export const MinyanContext = createContext({
    MinyanRecords: null,
    fetchMinyanRecords: () => {},
});

export function useMinyan() {
    return useContext(MinyanContext);
}
