import { useAuth } from "../../context/auth";
import Info from "./sections/Info";
import NotificationSettings from "./sections/Notifications";
import Password from "./sections/Password";

const Account = () => {
    document.title = "My Account | Dependable";
    const { user } = useAuth();

    return (
        <div>
            <div className="bg-center bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/main_bg.jpg')" }}>
                <div className="bg-gradient-to-r from-dependable to-dependable/75">
                    <div className="mx-auto max-w-7xl text-left md:py-20 py-10 px-5">
                        <p className="text-lg md:text-6xl text-yellow-500 md:mb-4 uppercase font-light tracking-widest">My Account</p>
                        <p className="text-white mt-2 text-lg">
                            {user.first_name} {user.last_name}
                        </p>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl px-5 mx-auto py-10 ">
                <Info />
                <Password />
                <NotificationSettings />
            </div>
        </div>
    );
};

export default Account;
