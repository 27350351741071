import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import Post from "../../helpers/ajax/post";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
    document.title = "Login | Dependable";
    const { fetchUser } = useContext(AuthContext);

    const [password, setPassword] = useState("");
    const [Username, setUsername] = useState("");
    const [loginError, setLoginError] = useState(null);
    const [LogginIn, setLogginIn] = useState(false);
    const [ShowPass, setShowPass] = useState(false);

    const handleLogin = async (ev) => {
        ev.preventDefault();
        if (!password || !Username) {
            return false;
        }
        setLogginIn(true);
        setLoginError(null);
        try {
            await Post("/login", {
                password: password,
                username: Username,
            });
            await fetchUser();
        } catch (error) {
            setLoginError(error.error);
        } finally {
            setLogginIn(false);
        }
    };

    return (
        <div style={{ backgroundImage: "url('/images/main_bg.jpg')" }} className="bg-center bg-no-repeat bg-cover">
            <div className="flex justify-center min-h-screen bg-white/90">
                <form onSubmit={handleLogin} className="bg-white w-96 shadow-2xl self-center inline-block rounded-lg p-8">
                    <img alt="logo" src="/images/dependable-logo-reg.jpg" className="inline-block mx-auto w-1/2" />
                    <h2 className="mt-4 mb-3">Login to Minyan tracker &amp; winterization</h2>
                    <input
                        required
                        className="w-full block mb-5 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30 p-3"
                        placeholder="Email address"
                        type="text"
                        value={Username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="relative">
                        <input
                            required
                            className="w-full block mb-5 rounded-md border focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30 p-3"
                            placeholder="Password"
                            type={ShowPass ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            data-tip={`${ShowPass ? "Hide" : "Show"} Password`}
                            type="button"
                            className="absolute right-5 top-4 bottom-4"
                            onClick={() => setShowPass(!ShowPass)}
                        >
                            {!ShowPass && <FaEye />}
                            {ShowPass && <FaEyeSlash />}
                        </button>
                    </div>
                    <button
                        disabled={LogginIn}
                        className="bg-dependable disabled:opacity-50 block text-white w-full p-3 font-bold rounded-md focus:outline-none focus:ring-2 ring-offset-2 ring-dependable/30"
                        type="submit"
                    >
                        Login
                        {LogginIn && (
                            <div className="ml-4 spinner-border animate-spin inline-block w-4 h-4 border-4 text-white rounded-full"></div>
                        )}
                    </button>
                    <p className="text-right mt-3 text-dependable">
                        <Link to={"/forgot"}>Don't have password?</Link>
                    </p>
                    {!!loginError && <p className="text-red-800 text-left mt-4">{loginError}</p>}
                </form>
            </div>
        </div>
    );
};

export default Login;
